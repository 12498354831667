@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .loader {
    --g1: conic-gradient(from 90deg at top 3px left 3px, transparent 90deg, #666666 0);
    --g2: conic-gradient(from -90deg at bottom 3px right 3px, transparent 90deg, #666666 0);
    background: var(--g1), var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2), var(--g2);
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-repeat: no-repeat;
    animation: boxy 1.5s infinite;
  }

  .dark .loader {
    --g1: conic-gradient(from 90deg at top 3px left 3px, transparent 90deg, #bbb 0);
    --g2: conic-gradient(from -90deg at bottom 3px right 3px, transparent 90deg, #bbb 0);
    background: var(--g1), var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2), var(--g2);
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-repeat: no-repeat;
    animation: boxy 1.5s infinite;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}


/* Scrollbar Track */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #d1d1d1; /* Light gray color */
  border-radius: 12px; /* Rounded corners */
  border: 2px solid #f5f5f5; /* Lighter border color */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); /* Inner shadow effect */
}

.dark ::-webkit-scrollbar-thumb {
  background-color: #666;
  border: 2px solid #666;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #b0b0b0; /* Darker gray color on hover */
}

.dark ::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f9f9f9; /* Track color */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); /* Inner shadow effect */
}

.dark ::-webkit-scrollbar-track {
  background-color: #444;
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
  background-color: #f2f2f2; /* Darker track color on hover */
}

.dark ::-webkit-scrollbar-track:hover {
  background-color: #333;
}

.handlebars-widget {
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-weight: normal;
  font-size: 12px;
  font-feature-settings: "liga" 0, "calt" 0;
  font-variation-settings: normal;
  line-height: 18px;
  letter-spacing: 0px;
  max-width: fit-content !important;
}

